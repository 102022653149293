import React from 'react';

const CommunityApp = () => {
    return (
        <div className="community-app-page">
            <h1>Community App</h1>
            <p>This application assists communities in managing their resources efficiently. It features a room sharing and booking system, enables the creation and management of groups, and supports conducting surveys and polls to enhance community engagement.</p>
            
            <div className="image-gallery">
                <img src="https://savaserturk.com/images/c6.png" alt="Community App Screenshot 1"  />
                <img src="https://savaserturk.com/images/c8.png" alt="Community App Screenshot 2" />
            </div>
            
            <h3>Technologies Used:</h3>
            <ul>
                <li>PHP API for backend operations</li>
                <li>Login and Registration Authentication System</li>
                <li>Email Verification to enhance security</li>
                <li>Push Notifications to keep users informed</li>
                <li>Google AdMob for monetization through ads</li>
                <li>Group Management functionality for organizing users</li>
            </ul>
            
            <a href="https://savaserturk.com/" target="_blank" rel="noopener noreferrer">Visit Project</a>
        </div>
    );
};

export default CommunityApp;
