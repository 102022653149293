import "./contact.css";
import location from "../../img/location.svg";
import mail from "../../img/mail.svg";
import phone from "../../img/phone.svg";
import { useContext, useRef, useState } from "react";
import emailjs from 'emailjs-com';
import { ThemeContext } from '../../context';

const Contact = () => {
    const formRef = useRef();
    const [done, setDone] = useState(false);
    
    const theme=useContext(ThemeContext)
    const darkMode = theme.state.darkMode;

    const handleSubmit = (e) =>{
        e.preventDefault();
        emailjs.sendForm('service_aciosco', 'template_km1ycuc', formRef.current, '9Gs6PsI3RlFqcxLCv')
      .then((result) => {
          console.log(result.text);
          setDone(true)
      }, (error) => {
          console.log(error.text);
      });
  };

    return (
        <div className="c">
            <div className="c-bg"> </div>
                <div className="c-wrapper">
                    <div className="c-left">
                        <h1 className="c-title">Let's discuss your project</h1>
                        <div className="c-info">
                            <div className="c-info-item">
                                <img src={location} alt="" className="c-icon"/>
                               Kanata, Ottawa, Canada
                            </div>
                            <div className="c-info-item">
                                <img src={mail} alt="" className="c-icon"/>
                               root@savaserturk.com
                            </div>
                            <div className="c-info-item">
                                <img src={phone} style={{}} alt="" className="c-icon"/>
                                -
                            </div>
                        </div>
                    </div>
                    <div className="c-right">
                        <div className="c-desc">
                            <b>What's your story! </b> Get in touch. Always available for new projects.
                        </div>
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Name" name="user_name"></input>
                            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Subject" name="user_subject"></input>
                            <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" name="user_email"></input>
                            <textarea style={{backgroundColor: darkMode && "#333"}} rows="5" placeholder="Message" name="message"/>
                            <button>Submit</button>
                            {done && "Thank you for message"}
                        </form>
                    </div>
          
            </div>
        </div>
    )
}

export default Contact
