import React from 'react';
import './CashCalendar.css'; // Ensure the path is correct

const CashCalendar = () => {
  return (
      <div className="cash-calendar-page">
          <h1>Cash Calendar App</h1>
          <p>This app allows users to track their earnings and expenditures, storing data securely in a local SQLite database. It's designed to help manage financial schedules effectively.</p>
          <div className="image-gallery">
              <img src="https://savaserturk.com/images/cashcalender.jpg" alt="Cash Calendar Screenshot 1" />
              <img src="https://savaserturk.com/images/cashcalender2.jpg" alt="Cash Calendar Screenshot 2" />
          </div>
          <h3>Technologies Used:</h3>
          <ul>
              <li>Flutter</li>
              <li>Dart</li>
              <li>SQLite</li>
              <li>Firebase Push Notifications</li> {/* Expanded technology list */}
          </ul>
          <a href="https://savaserturk.com/" target="_blank" rel="noopener noreferrer">Visit Project</a>
      </div>
  );
};

export default CashCalendar;
