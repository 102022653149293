import React, { useContext } from 'react';
import './skills.css';
import { ThemeContext } from '../../context'; // Adjust the import path as necessary

const Skills = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <section className="skills-section" style={{backgroundColor: darkMode ? '#333' : '#fff', color: darkMode ? '#fff' : '#333'}}>
      <h2 className="section-title" style={{color: darkMode ? '#fff' : '#333'}}>My Skills!</h2>
      <div className="skill-category">
        <h3 className="category-title" style={{color: darkMode ? '#aaa' : '#555'}}>Software Skills</h3>
        <ul className="skill-list">
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Web Development</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Mobile Development</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Database Management-SQL/NoSQL</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Software Development Life Cycle-SDLC</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>API Development</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Software Architecture Design</li>
          {/* Add more programming languages */}
        </ul>
      </div>
      <div className="skill-category">
        <h3 className="category-title" style={{color: darkMode ? '#aaa' : '#555'}}>IT Skills</h3>
        <ul className="skill-list">
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Network Administration</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Network Protocols</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Network Troubleshooting</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>Network Monitoring</li>
          <li className="skill-item" style={{color: darkMode ? '#ccc' : '#333'}}>System Administration</li>
          {/* Add more networking skills */}
        </ul>
      </div>
      {/* Add more skill categories */}
    </section>
  );
}

export default Skills;
