import "./about.css";
import { useContext, } from "react";
import { ThemeContext } from '../../context';

const About = () => {
    const theme=useContext(ThemeContext)
    const darkMode = theme.state.darkMode;

    return (
        <div className="a">
            <div className="a-left">
                <div className="a-card bg"></div>
                <div className="a-card">
                    <img src="https://envri.eu/wp-content/uploads/2016/08/software-developer-copy.jpg" alt="" className="a-img"/>
                </div>
            </div>
            <div className="a-right">
                <h1 className="a-title">About me</h1>
                <p className="a-sub">
                My academic journey has provided me with a solid foundation in computer science and engineering principles, equipping me with the knowledge and skills necessary to excel in the field of technology.
                </p>
                <p className="a-desc">
                Throughout my education and professional experiences, I have cultivated a strong aptitude for problem-solving, critical thinking.
                </p>
                <div className="a-award">
                    <div className="a-award-texts">
                        <h4 className="a-award-title" style={{color: darkMode && "white"}}>Computer Engineering Technology-Computing Science</h4>
                        <p className="a-award-desc">
                           Algonquin College - Ottawa, Canada
                        </p>
                    </div>
                </div>
                <div className="a-award">
                    <div className="a-award-texts">
                        <h4 className="a-award-title" style={{color: darkMode && "white"}}>Computer Programming</h4>
                        <p className="a-award-desc">
                            Necmettin Erbakan University - Konya, Turkey
                        </p>
                    </div>
                </div>
                <div className="a-award">
                    <div className="a-award-texts">
                        <h4 className="a-award-title" style={{color: darkMode && "white"}}>Network Operator (Cisco)</h4>
                        <p className="a-award-desc">
                           Golcuk Vocational High School - Kocaeli, Turkey
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
