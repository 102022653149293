export const products = [
    {
        id: 1,
        img: "https://savaserturk.com/images/savaserturk.png",
        link: "http://savaserturk.com",
        category: "Web",
        subcategory: "React",
        technologies: ["React", "CSS", "HTML"],
    },
    {
        id: 2,
        img: "https://savaserturk.com/images/tikka.png",
        link: "http://savaserturk.com",
        category: "Web",
        subcategory: "PHP",
        technologies: ["Wordpress", "WooCommerce"], 
    },
    {
        id: 3,
        img: "https://savaserturk.com/images/harmonyhues.png",
        link: "http://savaserturk.com",
        category: "Web",
        subcategory: "PHP",
        technologies: ["Wordpress", "WooCommerce"], 
    },
    {
        id: 4,
        img: "https://savaserturk.com/images/avashop.png",
        link: "http://savaserturk.com",
        category: "Web",
        subcategory: "React",
        technologies: ["MERN","React", "NodeJS", "Stripe","API"], 
    },    
    {
        id: 5,
        img: "https://savaserturk.com/images/socialmediaclone.png",
        link: "http://savaserturk.com",
        category: "Web",
        subcategory: "React",
        technologies: ["MERN", "React", "NodeJS","MongoDB"], 
    },    
    {
        id: 6,
        img: "https://i.ibb.co/104DLWw/screencapture-artismyjob-2022-03-16-17-41-11.png",
        link: "http://artismyjob.com",
        category: "Web",
        subcategory: "PHP",
        technologies: ["LAMP", "PHP", "HTML", "JS", "JQuery", "CMS", "API"], 
    },  
    {
        id: 7,
        img: "https://i.ibb.co/104DLWw/screencapture-artismyjob-2022-03-16-17-41-11.png",
        link: "http://savaserturk.com",
        category: "Desktop",
        subcategory: "Java SE",
        technologies: ["Java", "JavaFX"], 
    },
    {
        id: 8,
        img: "https://savaserturk.com/images/ra.jpg",
        link: "http://savaserturk.com",
        category: "Mobile",
        subcategory: "Flutter",
        technologies: ["Flutter", "Dart", "PHP-API", "ChatGPT Vision API"], 
    },
    {
        id: 9,
        img: "https://savaserturk.com/images/cashcalender.jpg",
        link: "http://savaserturk.com",
        category: "Mobile",
        subcategory: "Flutter",
        technologies: ["Flutter", "Dart", "SQLite"], 
    },
    {
        id: 10,
        img: "https://savaserturk.com/images/c3.jpg",
        link: "http://savaserturk.com",
        category: "Mobile",
        subcategory: "Flutter",
        technologies: ["Flutter", "Dart", "PHP-API", "CMS", "AUTH", "SharedPreferences", "Booking System", "FCM-Notifications"], 
    },
    {
        id: 11,
        img: "https://i.ebayimg.com/images/g/DcEAAOSwcjZk3yTX/s-l1200.jpg",
        link: "http://savaserturk.com",
        category: "IoT",
        subcategory: "Raspberry Pi",
        technologies: ["PN532", "NFC-Read/Write", "NFC Card and Phone Read/Write", "Python"], 
    },
    {
        id: 10,
        img: "https://savaserturk.com/images/dashboard.PNG",
        link: "http://savaserturk.com",
        category: "IoT",
        subcategory: "Teensy 4.0",
        technologies: ["NodeRed", "RGB TriColor", "Wifi-Bluetooth Controller"], 
    },
    {
        id: 11,
        img: "https://savaserturk.com/images/uipanel.PNG",
        link: "http://savaserturk.com",
        category: "IoT",
        subcategory: "Teensy 4.0",
        technologies: ["NodeRed", "Google-Speech", "Thermistor Sensor", "Swagger"], 
    },
    // Include 'technologies' for other products as well
];
