import React, { useState } from 'react';
import Modal from '../modal/Modal';
import CashCalendarDetails from '../ProjectDetails/CashCalendarDetails'; // Assuming you have this component
import CommunityApp from '../ProjectDetails/CommunityApp'; // Assuming you have this component
import ThirdEye from '../ProjectDetails/ThirdEye'; // Assuming you have this component

const HamburgerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  // Handle opening the modal with specific content
  const openModal = (Component) => {
    setModalContent(<Component />);
    setIsModalOpen(true);
    setIsMenuOpen(false);  // Optionally close the menu when a project is selected
  };

  // Handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <div className="hamburger-menu">
      <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="menu-button">
        {isMenuOpen ? 'Close' : 'Menu'}
      </button>
      {isMenuOpen && (
        <div className="menu-list">
          <button onClick={() => openModal(CashCalendarDetails)}>Cash Calendar</button>
          <button onClick={() => openModal(CommunityApp)}>CommunityApp</button>
          <button onClick={() => openModal(ThirdEye)}>ThirdEye-Ra</button>
          {/* Add more buttons for different projects */}
        </div>
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default HamburgerMenu;
