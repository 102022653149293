import "./product.css";

const Product = ({ img, link, technologies }) => {
    return (
        <div className="p">
            <div className="p-browser">
                <div className="p-circle"></div>
                <div className="p-circle"></div>
                <div className="p-circle"></div>
            </div>
            <a href={link} target="_blank" rel="noreferrer">
                <img src={img} alt="" className="p-img" />
            </a>
            <div className="p-details">
                <div className="p-tech">
                    {technologies && technologies.map((tech, index) => (
                        <span key={index} className="p-tech-item">{tech}</span>
                    ))}
                </div>
                {/* Placeholder for additional project details */}
                
            </div>
        </div>
    );
};

export default Product;