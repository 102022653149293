import React from 'react';

const ThirdEye = () => {
  return (
    <div className="third-eye-page">
      <h1>ThirdEye App</h1>
      <img src="https://savaserturk.com/images/ra.jpg" alt="ThirdEye App Interface" style={{ maxWidth: '100%', height: 'auto' }} />
      <p>The ThirdEye app utilizes cutting-edge image processing and AI to interactively respond to user queries based on visual content. It can process images to recognize elements and discuss them using ChatGPT, providing a unique blend of visual and conversational AI technologies.</p>
      
      <h3>Key Features:</h3>
      <ul>
        <li><strong>Image Processing:</strong> Advanced algorithms analyze images to detect and interpret details.</li>
        <li><strong>ChatGPT Integration:</strong> Uses ChatGPT-4 for generating insightful and context-aware responses based on the image analysis.</li>
        <li><strong>Interactive Queries:</strong> Users can ask questions about the content in the images, and the system provides detailed answers.</li>
      </ul>

      <h3>Technologies Used:</h3>
      <ul>
        <li><strong>ChatGPT-4:</strong> For processing natural language queries and generating responses.</li>
        <li><strong>PHP API:</strong> Backend services for handling image data processing and integration with the AI model.</li>
        <li><strong>Login/Register Auth System:</strong> Secure user authentication for personalized experiences.</li>
        <li><strong>Email Verification:</strong> Enhances security by verifying user identities during the registration process.</li>
        <li><strong>Request Rights:</strong> Limits to three AI requests per day per user to manage resource usage effectively.</li>
      </ul>

      <a href="https://savaserturk.com/third-eye" target="_blank" rel="noopener noreferrer">Visit Site</a>
    </div>
  );
};

export default ThirdEye;
