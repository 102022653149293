import "./intro.css";
import Me from "../../img/me.jpeg";
import googlePlayIcon from "../../img/googleplaystore.svg";
import appleStoreIcon from "../../img/appleappstore.svg";
import github from "../../img/GitHub.svg";
import instagram from "../../img/instagram.svg";
import linkedin from "../../img/linkedin.svg";
  const handleStoreClick = () => {
    alert("Coming soon!"); // Displays an alert with the message "Coming soon!"
  };
  
const Intro = () => {
    return (
        <div className="i">
            <div className="i-left">
                <div className="i-left-wrapper">
                    <h2 className="i-intro">Hello, My name is Savas</h2>
                    <h2 className="i-name">I am</h2>
                    <div className="i-title">
                      <div className="i-title-wrapper">
                            <div className="i-title-item">Web Developer</div>
                            <div className="i-title-item">Application Developer</div>
                            <div className="i-title-item">Mobile App Developer</div>
                            <div className="i-title-item">IoT Developer</div>
                            <div className="i-title-item">Hersey Developer</div>
                        </div>
                    </div>
                    <p className="i-desc">I specialize in developing web, mobile, and desktop applications...</p>
                    <div className="i-social">
                        <span className="i-social-icon" onClick={handleStoreClick}>
                            <img src={googlePlayIcon} alt="Google Play" style={{ maxWidth: '100%', height: 'auto' }} />
                        </span>
                        <span className="i-social-icon" onClick={handleStoreClick}>
                            <img src={appleStoreIcon} alt="Apple Store" style={{ maxWidth: '100%', height: 'auto' }} />
                        </span>
                        <a href="https://github.com/savaserturk" className="i-social-icon" target="_blank" rel="noopener noreferrer">
                            <img src={github} alt="Github" style={{ maxWidth: '80%', height: 'auto' }} />
                        </a>
                        <a href="https://www.linkedin.com/in/savaserturk/" className="i-social-icon" target="_blank" rel="noopener noreferrer">
                            <img src={linkedin} alt="Linkedin" style={{ maxWidth: '80%', height: 'auto' }} />
                        </a>
                        <a href="https://www.instagram.com/savaserturkk" className="i-social-icon" target="_blank" rel="noopener noreferrer">
                            <img src={instagram} alt="Instagram" style={{ maxWidth: '80%', height: 'auto' }} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="i-right">
                <div className="i-bg">
                    <img src={Me} alt="" className="i-img"/>
                </div>
            </div>
        </div>
    );
};





export default Intro
