import React, { useState } from 'react';
import "./productlist.css";
import Product from '../product/Product';
import { products } from '../data/data';

const ProductList = () => {
  const [activeCategory, setActiveCategory] = useState('Web'); // Default active category

  // Group products by main category and subcategory
  const groupedProducts = products.reduce((acc, product) => {
    acc[product.category] = acc[product.category] || {};
    acc[product.category][product.subcategory] = acc[product.category][product.subcategory] || [];
    acc[product.category][product.subcategory].push(product);
    return acc;
  }, {});

  const categories = Object.keys(groupedProducts);

  return (
    <div className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">My Works!</h1>
      </div>
      <div className="pl-tabs">
        {categories.map((category) => (
          <button
            key={category}
            className={`tab-item ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="pl-content">
        {Object.entries(groupedProducts[activeCategory] || {}).map(([subcategory, items]) => (
          <div key={subcategory}>
            <h2 className="pl-subcategory-title">{subcategory}</h2>
            <div className="pl-list">
              {items.map((product) => (
                <div key={product.id} className="product-wrapper">
                  <Product img={product.img} link={product.link} />
                  <div className="technologies">
                    {product.technologies.map((tech, index) => (
                      <span key={index} className="tech-item">{tech}</span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
